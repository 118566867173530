.rts___tabs___container {
    padding: 0 !important;
    margin-left:-75px;
    margin-right:-75px;
}
.rts___tabs {
    background-color: #CEE86B;
    padding:20px 0px;
    margin: 0 20px;
}

.rts___nav___btn {
    color: #2182FF !important;
    font-size: 35px !important;
}
.rts___btn {
    border: unset !important;
}
.rts___nav___btn:hover {
    background-color:unset !important
}

.rts___tab  {
    border-radius: unset !important;
    border: unset  !important;
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    color: #313131;
    border-right: 1px solid #2182FF !important;
    padding: 0px 40px !important;
}

.rts___tab:last-child {
    border-right: unset !important;
}

.rts___tab___selected {
    color: #2182FF !important;
    /* font-size: 32px !important; */
    font-weight: 800 !important;
    line-height: 40px !important;
    background-color: unset !important;
    box-shadow: unset !important;
}

@media screen and (max-width: 450px) {
    .rts___tabs {
        margin: 0 5px;
    }
    
    .rts___tabs___container {
        padding: 0 !important;
        margin-left: -18px;
        margin-right: -18px;
    }
    .rts___tab {
        padding: 0px 35px !important;
    }
    .grows_card .slick-list {
        padding: 0px 20px 0px 0px !important;
    }
}