.faqpage_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.faqpage_subcontainer {
    max-width: 1080px !important;
    width: 100%;
}

.sub_title {
    font-family: Kumbh Sans !important;
    font-size: 1.75rem !important;
    font-weight: 700 !important;
    line-height: 42px !important;
    color: #000000;
}




.accord {
    box-shadow: none;
    border-bottom: 1px solid #C1C1C1;
}
.accord:before {
    background-color: unset !important;
}

.accordian_summary {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 30px !important;
    color: #313131;
}
.accord_icon {
    color: #2182FF;
    border-radius: 5px;
    border: 2px solid #2182FF
}