.MuiDrawer-paper {
    width: 100%;
    height: calc(100% - 18px) !important;
    border-bottom: 18px solid #B0D22D;
}
.MuiBreadcrumbs-separator {
    color: #FFF;
}

.sticky-navbar-nav {
    justify-content: center !important;
    max-width: 1080px !important;
    padding: 0;
    left: unset;
    top:18px;
    background: #FFF !important;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.40);
    border-right: 18px solid #B0D22D;
}

.sticky-navbar-nav.sticky-navbar-hidden {
    transform: translateY(-150%);
}
.MuiButtonBase-root-MuiButton-root:hover {
    --variant-containedBg: unset !important;
    --variant-textBg: unset !important;
    --variant-outlinedBorder: unset !important;
    --variant-outlinedBg: unset !important;
}
@media (hover: hover) {
    .MuiButtonBase-root-MuiButton-root:hover {
        --variant-containedBg: unset !important;
        --variant-textBg: unset !important;
        --variant-outlinedBorder: unset !important;
        --variant-outlinedBg: unset !important;
    }
}

@media screen and (max-width: 450px) {
    .sticky-navbar-nav {
        background: unset !important;
        
        box-shadow: unset !important;
        border-right: unset !important;
    }
}