.applyForm_container {
    width: 100%;
    display:flex;
    justify-content: center;
}
.applyForm_subcontainer {
    max-width: 1080px !important;
    width: 100%;
}

.applyForm_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 39.69px !important;
    color: #313131;
}

.applyForm_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #313131;
}
.applyForm_thanks {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    color: #2182FF;
}
.applyForm_btn {
    background-color: #2182FF;
    border-radius: 5px;
    font-family: Kumbh Sans !important;
    font-size: 1.15rem !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    /* letter-spacing: 3px; */
    color: #FFFFFF !important;
    font-size: 0.9em !important;
    text-transform: none;
    padding: 10px 36px;
}
@media screen and (max-width:450px) {
    .applyForm_container {
        padding-left: 36px;
        padding-right: 36px;
    }
}