.expertise_container {
    width: 100%;
    padding-top:120px;
    padding-bottom: 120px;
    justify-content: center;
}
.expertise_subcontainer {
    max-width: 1080px !important;
    display: flex; 
    justify-content: space-between;
    column-gap: 50px;
}
.grid_container {
    /* row-gap: 2rem; */

}

.expertise_header {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    color: #313131 !important;
    font-weight: 700 !important;
    line-height: 48px !important;
}
.expertise_content {
    margin-top:40px !important;
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    color: #5A5A5A !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    width: 350px;
}
.expertise_button {
    margin-top:20px!important;
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    color: #2182FF !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    text-transform: none !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.expertise_image_container {
    width: 50%;
    display: flex;
    gap: 30px;
}

@media screen and (max-width:450px) {
    .expertise_container {
        padding-left: 18px;
        padding-right: 18px;
    }
    .expertise_subcontainer {
        flex-flow: column-reverse;
        row-gap: 20px;
    }   
    .grid_container {
        padding-left: 18px;
        padding-right: 18px;
    }
    .expertise_image_container {
        gap:20px;
        justify-content: center;
    }
    .expertise_image_container img {
        width: 100%;
        height: fit-content;
        max-width: 153px;
    }
    .expertise_content {
        width: 100%;
        margin-top:1rem !important;
    }
}