.value_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.value_subcontainer {
    max-width: 1080px !important;
    width: 100%;
}
.value_prepos {
    display: flex;
    gap: 1.75rem;
}
.values_card_container {
    display: flex;
    flex-flow: column;
    row-gap: 1.75rem;
}

.valuepre_card {
    background: url('https://simonindia.com/assets/images/value_bg.png') no-repeat;
    background-size: cover !important;
    background-position: center !important;
    padding: 3.2rem 4.2rem;
    min-height: 570px;
    border-bottom: 18px solid #00258A;
    border-radius: 5px 5px 0px 0px;
}
.value_card_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap:20px;
}

.value_card {
    background-color: #ECF2F7;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 10px solid #2182FF;
    padding: 2.5rem;
}


.explore_container {
    padding-top:80px;
    padding-bottom: 80px;
    background: url('https://simonindia.com/assets/images/explorebg.jpg') no-repeat;
    background-size: cover;
    background-position: center;
}

.main_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 39.69px !important;
}

.sub_title {
    font-family: Kumbh Sans !important;
    font-size: 1.75rem !important;
    font-weight: 700 !important;
    line-height: 42px !important;
    color: #000000;
}

.sub_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 35px !important;
    color: #313131;
}

.colorWhite {
    color: #FFF !important;
}
.colorBlue {
    color: #00258A !important;
}
.grow_card {
    display: flex;
    width:100%;
    justify-content: flex-start;
    column-gap:3.5rem;
    margin-top:2rem;
}
.grow_cards {
    width:100%;
    display: none;
    margin-top:2rem;
}
.grow_card_title {
    //styleName: Secondary Header;
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 30px !important;
    color: #313131;
}
.grow_button {
    color: #2182FF;
}


.accord {
    box-shadow: none;
    border-bottom: 1px solid #C1C1C1;
}
.accord:before {
    background-color: unset !important;
}

.accordian_summary {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 30px !important;
    color: #313131;
}
.accord_icon {
    color: #2182FF;
    border-radius: 5px;
    border: 2px solid #2182FF
}

.project_button {
    margin-top: 1rem !important;
    text-transform: none;
    text-decoration: none !important;
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    color: #2182FF !important;
    display: flex;
    column-gap:5px;
    align-items: center;

}
.project_button:hover{

}

@media screen and (max-width: 450px) {
    .value_prepos {
        padding-left: 18px;
        padding-right: 18px;
        flex-flow: column;
    }
    .valuepre_card {
        background: url('https://simonindia.com/assets/images/value_bg_mobile.jpg') no-repeat;
        padding: 3.2rem 18px;
    }
    .value_card_container {
        gap: 30px;
        justify-content: center;
    }
    .value_card_container img{
        width: 45%;   
    }
    .explore_container {
        width: 100%;
        padding-top:80px;
        padding-bottom: 80px;
        padding-left: 36px;
        padding-right: 36px;
        background: url('https://simonindia.com/assets/images/explorebg_mobile.jpg') no-repeat;
        background-size: cover;
    }
    .grow_card {
        display: none;
    }
    .grow_cards {
        display: flex;
        flex-flow: column;
        row-gap: 1rem;
    }
}