.culture_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.culture_subcontainer {
    max-width: 1080px !important;
    width: 100%;
}

.culturepeople_container {
    display: flex;
    justify-content: space-between;
    column-gap: 1.5rem;
    max-width: 1300px !important;
}
.culture_image_container{
    display: block;
    width: 100%; 
    justify-content: space-between; 
    column-gap: 1.5rem;
    max-width: 1300px !important;
}

.culture_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 39.69px !important;
    color: #313131 !important;
}

.culture_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #313131 !important;
}
.culture_sub_title {
    font-family: Kumbh Sans !important;
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    line-height: 26px !important;
    color: #00258A !important;
}

.culture_sub_sub_title {
    font-family: Kumbh Sans !important;
    font-size: 1.75rem !important;
    font-weight: 700 !important;
    line-height: 42px !important;
    color: #000000 !important;
}


.culture_btn {
    background-color: #2182FF !important;
    border-radius: 5px !important;
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    color: #FFF !important;
    padding: 6px 18px !important;
    text-transform: none !important;
}
.colorWhite {
    color: #FFFFFF !important;
}
.colorGray {
    color: #5a5a5a !important;
}
.explore_container {
    padding-top:80px;
    padding-bottom: 80px;
    background: url('https://simonindia.com/assets/images/explorebg.jpg') no-repeat;
    background-size: cover;
    background-position: center;
}
.grow_card {
    display: flex;
    width:100%;
    justify-content: flex-start;
    column-gap:3.5rem;
    margin-top:2rem;
}
.grow_cards {
    width:100%;
    display: none;
    margin-top:2rem;
}
.grow_card_title {
    //styleName: Secondary Header;
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 30px !important;
    color: #313131;
}
.grow_button {
    color: #2182FF;
}

.culture_card_container {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    position: relative;
}
.culture_card_subcontainer {
    position: absolute;
    bottom:0;
    background-color: #00258A;
    border-radius: 0px 0px 5px 5px;
    height: 154px;
    width: 725px;
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
    column-gap: 2rem;
}

@media screen and (max-width:450px) {
    .culture_container {
        padding-left: 18px;
        padding-right: 18px;
    }
    .culture_card_container {
        flex-flow: column;
    }   
    .culture_card_container img {
        width: "100%";
    }
    .culture_image_container {
        max-width: 100% !important;
    }
    .culture_card_subcontainer {
        margin-top: -4px;
        position: relative;
        flex-flow: column;
        width: 100%;
        row-gap: 1rem;
        padding: 1rem;
        height: auto;
        top: unset;
        bottom: unset;
    }
    .culturepeople_container {
        flex-flow: column;
        row-gap: 2rem;
    }
    .explore_container {
        width: 100%;
        padding-top:80px;
        padding-bottom: 80px;
        padding-left: 36px;
        padding-right: 36px;
        background: url('https://simonindia.com/assets/images/explorebg_mobile.jpg') no-repeat;
        background-size: cover;
    }
    .grow_card {
        display: none;
    }
    .grow_cards {
        display: flex;
        flex-flow: column;
        row-gap: 1rem;
    }
}