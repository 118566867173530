.page_container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
}
.page_subcontainer {
    max-width: 1080px !important;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin-top: 5rem;
    margin-bottom: 100px;
}
.page_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #313131;
    margin-bottom: 5rem !important;
}
.page_sub_content {
    font-family: Kumbh Sans !important;
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    color: #00258A;
    text-align: left;
    width: 100%;
    margin-bottom: 2rem !important;
}

@media screen and (max-width: 450px) {
    .page_subcontainer {
        margin-top: 5rem;
        margin-bottom: 80px;
        padding-left: 36px;
        padding-right: 36px;
    }
    
}