.supplyForm_container {
    width: 100%;
    display:flex;
    justify-content: center;
}
.supplyForm_subcontainer {
    max-width: 1080px !important;
    width: 100%;
}

.supplyForm_tabcontainer {
    max-width: 1080px !important;
    width: 100%;
}
.tab_btn {
    width: 33.25%;
    cursor: pointer;
    border-bottom: 2px solid #00258A;
    text-align: center;
    padding-top: 21px;
    padding-bottom: 21px;
}
.tab_btn:not(:last-child) {
    border-right: 1px solid #00258A;
}

.tab_btn:hover, .active {
    border-bottom: 8px solid #CEE86B;
}
.tab_btn:hover p, .active p{
    color: #00258A !important;
    font-weight: 700 !important;
}

.supplyForm_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 39.69px !important;
    color: #313131;
}

.supplyForm_subcontent {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #5A5A5A;
}


.supplyForm_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #313131;
}
.applyForm_thanks {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    color: #2182FF;
}
.formGrid {
    margin-bottom: 1rem;
}

.inputStyle {
    font-family:Kumbh Sans !important;
    font-size:1em;
    color: #313131 !important;
    background-color: #FFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    height: 1rem;
    width: 100%;
    padding: 1em;
}

.supplyForm_btn {
    background-color: #2182FF;
    border-radius: 5px;
    font-family: Kumbh Sans !important;
    font-size: 1.15rem !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    color: #FFFFFF !important;
    text-transform: none !important;
    padding: 15px 36px !important;
}

.provide_btn {
    background-color: #CEE86B !important;
    border-radius: 5px 5px 0px 0px !important;
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
    line-height: 26px !important;
    color: #313131 !important;
    text-transform: none !important;
    padding: 6px 18px !important;
    position: absolute !important;
    bottom: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap:5px;
    
}

@media screen and (max-width: 450px) {
    .supplyForm_container {
        padding-left: 18px;
        padding-right: 18px;
    }
}