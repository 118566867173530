.header_container {
    justify-content: space-between;
    max-width: 1080px !important;
    width:100%;
    position: fixed;
    /* top:18px; */
    background-color: #FFF;
    border-radius: 5px;
    z-index:100;
    /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.40); */
    /* border-right: 18px solid #B0D22D; */
    /* top: 18px; */
    transition: top 0.3s;
}

.header_subcontainer {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1080px !important;
}
.header {
    display: flex;
    justify-content: center;
}
.headerMenu {
    border-bottom: none;
}
/* .headerMenu:hover {
    border-bottom: 6px solid #CEE86B; */
/* } */
.pop_link {
    padding: 2px 20px !important;
    align-items: flex-start !important;
    border-bottom: unset !important;
}
.pop_link:hover {
    border-bottom: unset !important;
}

.nav {
    display: inline-flex;
    list-style: none;
    justify-content: space-around;
    width: 100%;
    padding-inline-start: 0px;
    margin:0;
}
.nav li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; 
    font-family: Kumbh Sans !important;
    font-size: 1rem;
    font-weight: 600;
    color: #2182FF;
    cursor: pointer;
}
.nav li:last-child {
    border-right: none;
}
.headerli {
    text-align: left; 
    padding: 0.30em 0px;
    border-right: none;
}
.nav a {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 23px 36px;
    /* padding-right:36px;  */
     /* padding-left: 36px; */
    text-decoration: none;
    /* text-align: center; */
    font-size: 1rem;
    font-weight: 600;
    border-bottom: 6px solid rgba(255, 255, 255, 0.1);

}
.nav a:hover {
    /* font-weight: 700; */
    color: #00258A;
    border-bottom: 6px solid #CEE86B;
}
.nav a::after {
    content: attr(data-text);
    content: attr(data-text)/"";
    height: 0;
  visibility: hidden;
  overflow: hidden;
  user-select:  none;
  pointer-events: none;
    font-weight: 700;
}
.menuFontWeight {
    font-weight: 400 !important;
}
.pop_menu {
display: flex;
column-gap: 3px;
align-items: center;
}
.nonpop_hover:hover {
    padding-bottom:"20px !important"
}
 
.headerMenu a:focus-visible {
    outline: unset !important;
    outline-offset: unset !important;
}
.dropLinkMobile span {
    font-family: Kumbh Sans !important;
    font-weight: 600 !important;
    padding: 0px 1em 0.5em !important;
    color: #2182FF !important;
    /* border-bottom:  1px solid #C1C1C1 !important; */
    textDecoration: none !important;
}

@media screen and (max-width: 1080px) {
    .header_container {
        background-color: transparent;
        box-shadow: none;
        border-right: unset;
    }
    .header_logo {
        width: 100px;
        height: 50px;
    }
    .header_subcontainer { 
        justify-content: space-between;
        align-items: flex-start;
        margin-left: 18px;
        margin-right: 18px;
        border-radius: 5px;
        /* padding-left: 18px; */
        /* padding-right: 18px; */
    }
    .menucolor {
        color: #2182FF !important;
    }
    .menuFontSize {
        font-weight: 400 !important;
    }
}
@media speech {
    .nav a::after {
      display: none;
    }
  }