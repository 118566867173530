.leadership_container {
    width: 100%;
    display:flex;
    justify-content: center;
}
.leadership_subcontainer {
    max-width: 1080px !important;
    width: 100%;
}

.leader_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 39.69px !important;
    color: #313131;
}
.leader_card {
    max-width: 216px;
    width:216px;
    border-bottom: 1px solid #c1c1c1;
    box-shadow: none;
}
.leader_card_title {
    font-family: Kumbh Sans !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    color: #313131;
}
.leader_card_desig {
    font-family: Kumbh Sans !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    color: #2182FF;
}
.leader_bottom {
    margin-bottom: 3rem !important;
}
.board_team_title {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 700 !important;
    line-height: 48px !important;
    color: #B0D22D;
}
.board_team_design {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 500 !important;
    line-height: 36px !important;
    color: #00258A;
}
.board_team_description {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #5A5A5A;
}
.board_button {
    margin-top:2rem !important;
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    padding:0;
    text-transform: none !important;
    color: #2182FF;
    float:right;
}


.board-slider {

}
.board-slider .slick-next, .board-slider .slick-prev {
    top: 30%;
}