.explore_container {
    width: 100%;
    display: flex;
    justify-content: center;
}
.explore_subcontainer{
    max-width: 1080px !important;
    width: 100%;
}

.explore_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    color: #313131;
}
.explore_sub_title {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    color: #FFF;
}
.project_dest {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
    line-height: 26px !important;
    color: #FFF;
    position:absolute;
    top: 19px;
    left: 18px;
    max-width: 350px;
    width: 348px;
    word-spacing: -1.2px;
}
.project_card_title {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    color: #FFF;
    position:absolute;
    top: 63px;
    left: 18px;
    max-width: 350px;
    width: 348px;
    word-spacing: -1.2px;
}


.project_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    color: #FFF;
}

.project_container {
    background-size: cover;
    display: flex;
    flex-flow: column;
    padding: 19px;
    justify-content: flex-end;
    width: 100%;
    height: 500px;
    border-radius: 0px;
}
.project_button {
    margin-top: 1rem !important;
    text-transform: none;
    text-decoration: none !important;
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    color: #2182FF !important;
    display: flex;
    column-gap:5px;
    align-items: center;

}
.project_button:hover{

}


@media screen and (max-width: 450px) {
    .project_card_title {
        width: 233px;
        max-width: 300px;
    }
}