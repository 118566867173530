.safety_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.safety_subcontainer {
    max-width: 1080px !important;
    width: 100%;
}
.safetyContainer {
    padding: 2rem 5rem;
    background-color: #DAF0FF;
}
.safetyorder {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
}
.tipBar {
    height: 82px;
    background-color: #CEE86B;
    width: 100%;
    display: flex;
    justify-content: center;
}
.tipBarLine {
    background-color: #00258A;
    height: 1px;
    width: 100%;
    position: absolute;
    margin-top: 42px;
    z-index:0; 
}
.barimg_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #CEE86B;
    border-radius: 100px;
    width: 187px;
    height: 187px;
}

.safety_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    color: #313131 !important;
}
.safety_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 35px !important;
    color: #5A5A5A !important;
}
.safety_card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    width: 120px;
    height: 120px;
    border-radius: 100px;
    background-color: #FFFFFF;
}
.safetybar_content {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 30px !important;
    color: #00258A !important;
    margin-top: 0.8rem !important;
    text-wrap-mode: nowrap;
}
.safety_tip_container {

}
.safetypro_title {
    background-color: #CEE86B;
    padding: 5px 30px;
    color: #00258A !important;
}
.safety_subcontent {
    margin-bottom: 1rem;
    color: #313131 !important;
    font-weight: 700 !important;
    font-size: 28px !important;
}

@media screen and (max-width: 450px) {
    .safety_container {
        padding-left: 36px;
        padding-right: 36px;
    }
    .safety_tip_container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .safetyContainer {
        padding: 1rem;
        border-radius: 5px;
    }
    .safetyorder {
        flex-flow: column;
    }
    .tipBar {
        height: 50px;
    }
    .tipBarLine {
        margin-top:25px;
    }
    .barimg_container {
        width: 90px;
        height: 90px;
        margin-left: 18px;
    }
    .barimg_container img  {
        width: 55%;
    }
    .safety_card {
        width: 108px;
        height: 108px;
    }
    .safetybar_content {
        text-align: center;
        width:108px;
        text-wrap-mode: unset;
    }
    .safetypro_title {
        padding: 5px 10px;
    }
    .safety_subcontent {
        padding-top: 2rem !important
    }
}