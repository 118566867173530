
.pageContainer {
    background: url("https://simonindia.com/assets/images/pagebg.jpg") no-repeat;
    background-size:cover;
    background-position: center;
    display: flex;
    justify-content: center;
    height: 360px;
}

.pageSubContainer  {
    max-width: 1080px !important;
    width: 100%;
    padding-top:200px;
    display: flex;
    justify-content: space-between;
}
.bannerTitle {
    font-family: Kumbh Sans !important;
    font-size: 2.25rem !important;
    font-weight: 700 !important;
    line-height: 44.65px !important;
    color: #FFF;
}
.breadcrumb_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 300 !important;
    line-height: 19.84px !important;
    color: #FFF !important;
}
.breadcrumb_active {
    font-weight: 500 !important;
}

.pageSubContainer .MuiBreadcrumbs-separator {
    color: #FFF;
}

@media screen and (max-width: 450px) {
    .pageContainer {
        background: url("https://simonindia.com/assets/images/pagebg_mobile.png") no-repeat;
        height: 248px;
        background-size:cover;
    }
    .pageSubContainer {
        flex-flow: column;
        padding-top:100px;
        padding-left: 18px;
        padding-right: 18px;
        row-gap: 10px;
    }
    .pageSubContainer img {
        width: 262px;
        float: right;
        margin-right: 0px;
        margin-top:8px;
    }
    .bannerTitle {
        font-size : 2rem !important;
        line-height: 48px !important;
    }
    .breadcrumb_content {
        font-size: 0.8rem !important;
        line-height: 17.36px !important;
    }
}