.service_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.service_subcontainer {
    width: 100%;
    max-width: 1080px;

}
.service_contain {
    background-color: #ECF2F6;
    margin-top: -140px;
    padding-top: 190px;
    padding-bottom: 100px;
}
.service_subcontain{
    display: flex;
    width: 100%;
    gap: 50px;
}

.service_filter {
    filter: grayscale(100);
}
.service_disablefilter {
    filter: unset;
}

.title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    color: #313131;
}
.sub_title {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    color: #FFF;
}
.service_card {
    max-width: 155px;
    width: 155px;
    cursor: pointer;
}
.service_card_content {
    padding: 33px 18px;
    min-height: 100px;
    background-color: #FFF;
}
.service_card_line {
    height: 2px;
    margin-bottom: 0.5rem;
    background-color: #c1c1c1;
}
.service_card:hover .service_title {
    color: #00258A;
} 


.customer_title {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    color: #313131;
    margin-top: 1.75rem !important;
}
.customer_desig{
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 24.8px !important;
    color: #00258A;
    margin-top: 1rem !important;
}
.customer_location {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 19.84px !important;
    color: #5A5A5A;
}

.service_title {
    font-family: Kumbh Sans !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 27px !important;

}

.service_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #313131 !important;
}

.experience_card {
    display: flex;
    width: 100%;
    gap: 40px; 
    justify-content: space-between;
}

.experience_title {
    font-family: Kumbh Sans !important;
    font-size: 2.5rem !important;
    font-weight: 400 !important;
    line-height: 52.09px !important;
    color: #00258A !important;
    margin-bottom: 2rem !important;
}
.experience_line {
    background-color: #B0D22D;
    height: 2px;
    width: 100%;
    max-width: 216px;
}
.experience_desc {
    margin-top: 1rem !important;
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #00258A !important;
}

@media screen and (max-width: 450px) {
    .service_contain {
        padding-left: 36px;
        padding-right: 36px;
        margin-top:-140px;
    }
    .service_subcontain {
        display: flex;
        flex-flow: column-reverse;
    }
    .service_subcontain img {
        width: 100%;
        border-radius: 5px;
    }
    .experience_card {
        flex-flow:column;
    }
    .experience_line {
        max-width: 100%;
    }
    .customer_title { 
        font-size: 1.25rem !important;
    }
    .service_card {
        max-width: 216px;
        width: 216px;
        cursor: pointer;
    }
}