.client_container {
    display:flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 200px;
}
.client_subcontainers {
    padding-top: 100px;
    padding-bottom: 100px;
    width: 100%;
    margin-top: -80px;
    background-color: #ECF2F6;
    margin-left:80px;
    margin-bottom: -100px;
    display: flex;
    justify-content: center;
}
.client_subcontainer {
    width: 100%;
    max-width: 1080px !important;
    margin-left: -5rem !important;
}

.client_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 48px !important;
    color: #313131;
    margin-bottom: 1rem !important;
}

.client_user_title {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 30px !important;
    color: #00258A;
    margin-bottom: 0.7rem !important;
}
.userDiv {
    padding-left: 20px;
    width: 30%; 
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;
}
.userDiv img {
    width: 100%;
}
.usercontent_div {
    width:70%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.userBack {
    margin-top:130px;
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    background-color: #FFFFFF;
    border-right: 18px solid #00258A;
    border-radius: 5px 5px;
    display: flex;
}
.client_user_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    color: #313131;
}
.client_arrow {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: -80px;
}
.client_feed {
    margin-top: 1rem !important;
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #313131;

}

@media screen and (max-width: 450px) {
    .client_container {
        padding-left: 18px;
        /* padding-right: 36px; */
        paddding-bottom: 200px;
        /* flex-flow: column; */
    }
    .client_subcontainers {
        margin-left: 0px;
    }
    .client_subcontainer {
        margin-left: 2rem !important;
        margin-right: 2rem;
    }
    .userBack {
        flex-flow: column;
        margin-top: 80px;
        background-color: #FFF;
        border-right: unset;
        border-bottom: 18px solid #00258A;
        /* row-gap: 20px; */
    }
    .client_feed {
        margin-top: 3rem !important;
    }
    .usercontent_div {
        width: 100%;
        text-align: center;
        align-items: center;
        background-color: #FFF;
        padding-top:10px;
        padding-bottom: 1rem;
        
    }
    .userDiv {
        padding-left: 0px;
        width: 100%;
        align-items: center;

    }
    .client_arrow {
        margin-bottom: -40px;
    }
    .client_arrow img {
        width: 20%;
    }
    .userDiv img {
        width: 100%;
    }
    .client_title {
        font-size: 1.8rem !important;
        line-height: 34.73px !important;
        margin-bottom: 2rem !important;
    }
    .client_user_title {
        margin-bottom: 0.2rem !important;
    }
    .client_user_content {
        padding-left: 18px;
        padding-right: 18px;
        font-size: 0.8rem !important;
        /* color: #00258A; */
    }
}