.homepossible_container {
    background: url("https://simonindia.com/assets/images/redefinebg.png") no-repeat;
    background-size:cover;
    background-position: center right;
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
}

.redefined_maincontainer {
    max-width: 1080px;
    width: 100%;
}

.redefined_heading {
    font-family: Kumbh Sans !important;
    font-size: 1.7rem !important;
    font-weight: 700 !important;
    color: #FFF;
    margin-bottom: 1rem !important;
}
.redefined_text {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    color: #2182FF;
}
.redefined_container {
    display: flex;
    justify-content: space-between;
    column-gap: 7rem !important;
    margin-top: -3rem !important;
    align-items: end !important;
    flex-wrap: nowrap !important;
}
.redefined_bigtext {
    margin-top: 70px;
    font-family: Kumbh Sans !important;
    font-size: 3.1rem !important;
    font-weight: 500 !important;
    color: #2182FF;
    margin-bottom: 1rem !important;
}

.redefined_bigtext_subheading {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: #FFF;
    margin-top: 10px !important;
    
}
.redefined_image {
    display: inline-block;
    width: max-content;
    user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  pointer-events: none;
}
.redefined_image > :first-child {
    -webkit-box-reflect: below 10px linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
}
/* .redefined_image > :last-child {
    transform: rotatex(180deg) translatey(10px);
    mask-image: linear-gradient(transparent 50%, white 90%);
    -webkit-mask-image: linear-gradient(transparent 50%, white 90%);
    background-image: unset;

} */

@media screen and (max-width: 450px) {
    .homepossible_container {
        background: url("https://simonindia.com/assets/images/redefinebg_mobile.jpg") no-repeat;
        padding-left: 36px;
        padding-right: 36px;
        background-size: cover;
    }
    
    .redefined_container {

        flex-flow: column !important;
        flex-wrap: nowrap !important;
        align-items: flex-start !important;
        margin-top:2rem !important;
        row-gap: 30px !important;
    }
}