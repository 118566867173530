.newsUpdate .slick-dots {
    bottom: -40px !important;
}

/* .rts___tabs {
    background: unset !important;
    padding: 0px 0px 10px !important;
    margin: 0px !important;
}

.rts___tab {
    border-radius: 5px !important;
    padding: 0px 42px !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.40) !important;
    background-color: #FFF;
    color: #00258A;
    margin: 0 13px !important;
    border-right: unset !important;
}
.rts___tab___selected {
    color: #FFF !important;
    background-color: #00258A !important;
    font-size: 1rem !important;
}
.rts___tabs___container {
    margin-left: 0px !important;
    margin-right: 0px !important;
} */