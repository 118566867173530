.partner_container {
    max-width: 1080px !important;
    width: 100%;
    padding-top:60px !important;
    padding-bottom: 80px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
}
.MuiCardContent_root {
    padding:0px !important;
}
.partnerSlider {
    display: flex;
    column-gap:30px;
}
.partner_card {
    max-width: 216px;
    width: 100%;
    box-shadow: unset !important;
}
.partner_cardMedia {
    height: 144px;
}

.partner_heading {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    color: #313131 !important;
    line-height: 48px !important;
    margin-bottom: 40px !important;
}
.partner_text {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #5A5A5A !important;
}
.partner_second_heading {
    font-family: Kumbh Sans !important;
    font-size: 1.2rem !important;
    font-weight: 600 !important;
    color: #00258A !important;
    line-height: 30px !important;
    margin-top: 5px !important;
    margin-bottom: 16px !important;
}
.partner_content {
    padding: 16px 0px !important;
}


@media screen and (max-width: 1024px){
    .partner_container {
        padding-top:10px !important;
    }
    .partner_card {
        max-width: 162px;
    }
    .partner_cardMedia {
        height: auto;
    }
    .partner_heading {
        font-size: 1.5rem !important;
        line-height: 36px !important;
     }
     .partner_second_heading {
        font-size: 15px !important;
        line-height: 22.5px !important; 
     }
     .partner_text {
        font-size: 14px !important;
        line-height: 21px !important; 
     }
     
}

@media screen and (max-width: 767px) {
    .partner_container {
        flex-flow: column;
        padding-left: 36px !important;
        padding-right: 36px !important;
    }
    .partner_card {
        max-width: 100%;
        display: flex;
        flex-flow: column;
        row-gap: 30px;
        background-color: unset !important;
    }
    .partner_cardMedia {
        height: auto;
    }
    .partner_heading {
        margin-bottom: 20px;
    }
    .partner_second_heading {
        width: 100%;
        text-align: center;
        margin-bottom: 20px !important;
    }
    .MuiCardContent_root {
        row-gap:10px;
    }
    
}