.MuiOutlinedInput-notchedOutline {
    border-width: 0px;
    padding: 0px;
}
.MuiSelect-select {
    padding-left: 0px !important;
}
.MuiOutlinedInput-root {
    outline: unset !important;
}
.MuiOutlinedInput-notchedOutline {
    border-width: 0px !important;
}
.MuiPagination-ul {
    justify-content: center;
}
.MuiPaginationItem-root {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    color: #5A5A5A !important;
}
.Mui-selected {
    background-color: #2182FF !important;
    color: #FFF !important; 
}

#projectExplpore .slick-track {
    margin-left: 0;
}

@media screen and (max-width:450px) {
    #demo-select-small{
        width:100px !important;
    }
    .MuiSelect-select {
        padding-left: 0px !important;
        width: 130px !important;
    }
    
}