.grow_container {
    width: 100%;
    background:url('https://simonindia.com/assets/images/grow_bg.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    padding-top:80px;
    padding-bottom:150px;
    display: flex;
    justify-content: center;
}
.grow_subcontainer {
    max-width: 1080px !important;
    width:100%;
    display: flex;
    justify-content: space-between;
    column-gap:30px;
}
.grow_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 48px !important;
    color: #FFFFFF;
    margin-bottom: 2rem !important;
}
.grow_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #FFFFFF;
}
.grows_card {
    display: flex;
    column-gap:18px;
    justify-content: space-between;
}
.grow_card_title {
    //styleName: Secondary Header;
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 30px;
    color: #313131;
}
.grow_button {
    margin-top: 30px !important;
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 26px;
    color: #2182FF;
    text-transform: none !important;
}
.card_container {
    max-width: 232px;
    width: 232px;
    border-bottom: 10px solid #CEE86B;
}
.card_media {
    height: 144px;
}
.card_content {
    padding: 18px !important;
}

@media screen and (max-width: 450px) {
    .grow_container {
        background:url('https://simonindia.com/assets/images/grow_bg_mobile.jpg') no-repeat;
        padding-left: 36px;
        padding-right: 36px;
        background-size: cover;
    }
    .grow_subcontainer {
        flex-flow: column;
    }
    .grows_card {
        row-gap: 18px;
        flex-flow: column;
        margin-top:2.5rem;
        margin-left: -9px;
    }
    .card_container {
        display:flex;
        min-width: 100%;
        width: 100%;
        border-right: 10px solid #CEE86B;;
        border-bottom: unset;
        column-gap:25px;
    }
    .card_media {
        width: 103px;
        height: 118px
    }
    .grow_button {
        margin-top:0px !important;
    }
    .card_content {
        padding-left: 0px !important;
        padding-right: 0px !important
    }
}