.banner_container {
    /* background: url("https://simonindia.hudestudio.com/assets/images/banner-image.png") no-repeat; */
    background-position: center;
    background-size: cover;
    min-height: 600px;
    height: auto;
    max-width:100%;
}

.holicontainer {
    background-color: #CEE86B;
    width:100%;
    display: flex;
    justify-content: center;

}
.holisubcontainer {
    max-width: 1080px;
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.holititle {
    font-family: Kumbh Sans !important;
    font-size: 2.5rem  !important;
    font-weight: 600 !important;
    line-height: 63px !important;
    color: #FFF;
}
.holiblue {
    color: #2182FF !important;
}
.project_slider .slick-dots{
    /* bottom: -50px !important; */
}

.project_slider .slick-dots li button{
    width: 30px !important;
    height: 30px !important;
}

.project_slider .slick-dots li.slick-active button:before {
 color: #2182FF;
}
.project_slider .slick-dots li button:before {
    color: rgba(90, 90, 90, 0.3);
    opacity: 1;
}
.project_slider .slick-prev {
    left : -50px;
}
.project_slider .slick-next {
    right : -50px;
}


@media screen and (max-width:450px) {
    .banner_container {
        min-height: 100px;
    }
    .holisubcontainer {
        padding: 36px;
        padding-bottom: 100px !important;
        align-items: flex-end;
    }
    .holisubcontainer img {
        width: 33%;
        margin-bottom:-55px;
        margin-right: 0px;
    }
    .holititle {
        font-size: 2.6rem !important;
        line-height: 52px !important;
    }
}