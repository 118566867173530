.homenews_container {
    padding-top:20px;
}

.whatsnew_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.whatsnew_subcontainer {
    max-width: 1080px !important;
    width: 100%;
}

.whatsnew_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 48px !important;
    color: #313131 !important;
}

.whatsnew_subtitle {
    font-family: Kumbh Sans !important;
    font-size: 1.20rem !important;
    font-weight: 600 !important;
    line-height: 30px !important;
    color: #313131 !important;
}
.whatsnew_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #313131 !important;

}

.news_content_title {
    font-family: Kumbh Sans !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    color: #313131 !important;

}

.tabpanel_container {
    width: 100%;
    border-bottom: 1px solid #C1C1C1;
    padding-bottom: 1rem;
    padding-top: 1rem;
}
.tabpanel_grid {
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.award_card {
    max-width: 320px;
    width: 320px;
    display: flex;
    flex-flow: column;
    align-items: center;
    box-shadow: none !important;
}
.award_card_grid {
    min-height: 210px;
    border-radius: 5px;
    padding: 18px;
    background-color: #ECF2F7;
    border-bottom: 6px solid #CEE86B;
}

.cardContainer {
    display: block;
    gap: 30px;
    justify-content: space-between;
}

.cardContent {
    background-color: #2182FF;
    justify-content: space-between;
    min-height: 213px;
    padding: 18px;
    display: flex;
    flex-flow: column;
    border-radius: 0px 0px 10px 10px;
}
.cardContentReside {
    background-color: #ECF2F7;
} 
.newscardtitle {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 30px !important;
    color: #FFF;
}
.newscard_content {
    font-family: Kumbh Sans !important;
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    color: #FFF;
}


.newscard_tcolor {
    color: #00258A;
}
.newscard_ccolor {
    color: #5A5A5A;
}
.newsButton {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    color: #2182FF !important;
    text-transform: none !important;
}
.whats_title_container {
    display: flex; 
    align-items:end; 
    column-gap: 8px
}

.award_card_container {
    display: flex;
    margin-top: 4rem;
    justify-content: space-between;
}
.modalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    background-color: #FFF;
    padding: 15px;
}

@media screen and (max-width: 450px) {
    .award_card_container {
        flex-flow: column;
        align-items: center;
    }
    .whatsnew_container {
        padding-left: 18px;
        padding-right: 18px;
    }
    .whatsnew_subtitle {
        font-size: 1.25rem !important;
    }
    .whats_title_container {
        padding-left: 18px;
        padding-right: 18px;
        flex-flow: column;
        /* align-items: center; */
    }
    .whats_title_container img{
        margin-top: -68px;
    }
    .cardContainer {
        flex-flow: column;
    }
    .award_card {
        width: 100%;
        max-width: 100%;
    }
    .news_content_title { 
        width: 80%;
    }
    
    .whatsnew_content {
        font-size: 1rem !important;
        line-height: 30px !important;
    }
}