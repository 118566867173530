.banner_section {
    width: 100%;
    max-width:100% !important;
    /* max-width: 1080px !important; */
    padding-left: 0px !important;
    padding-right: 0px !important;
    /* padding-top:190px; */
    /* padding-bottom: 220px; */
}

.banner_text {
    font-family: Kumbh Sans !important;
    font-size: 2.5rem !important;
    color: #FFFFFF;
    font-weight: 700;
}
.banner_image {
    margin-bottom: 1.5rem;
}
.heading_container {
    display: flex;
    flex-flow: column;
    row-gap: 1rem;
    
}
.text_container {
    display:flex;
    align-items: flex-end;
    margin-top: -4.2rem;
}
.text_container p {
    font-family: Kumbh Sans !important;
    font-size: 1.5rem !important;
    color: #FFFFFF;
    font-weight: 400;
}