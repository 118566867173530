.projectgallery_container{
    width: 100%;
    display: flex;
    justify-content: center;
    
}

.projectgallery_subcontainer {
    max-width: 1080px !important;
    width: 100%;
}

.project_background {
    background: url("https://simonindia.com/assets/images/news_bg.jpg") no-repeat;
    background-size: cover;

}

.projectgallery_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    color: #313131 !important;
}

.projectgallery_subtitle {
    font-family: Kumbh Sans !important;
    font-size: 1.7rem !important;
    font-weight: 600 !important;
    line-height: 40px !important;
}
.projectgallery_large_content {
    font-family: Kumbh Sans !important;
    font-size: 2.7rem !important;
    font-weight: 600 !important;
    line-height: 48px !important;
}

.projectgallery_sub_title {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 30px !important;
    color: #00258A !important;
}


.projectgallery_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 35px !important;
    color: #313131 !important;
}

.whatsnew_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #FFF !important;

}

.news_content_title {
    font-family: Kumbh Sans !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    color: #FFF !important;

}
.tabpanel_grid {
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.tabpanel_container:not(:last-child) {
    width: 100%;
    border-bottom: 1px solid #C1C1C1;
    padding-bottom: 0.8rem;
    display: flex;
    align-items: flex-end;
}

.tabpanel_container:not(:first-child) {
    padding-top: 1.5rem;
}
.news_container {
    display: flex;
    padding-top: 0.5rem;
    align-items: center;
    justify-content: space-between;
    width: 50%;
}
.news_line {
    height: 18px;
    width: 1px;
    border-right: 1px solid #FFF;
}

.projectindusty_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    color: #5A5A5A;
}


.projectall_title {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 30px !important;
    color: #00258A !important;
}
.projectall_content {
    font-family: Kumbh Sans !important;
    font-size: 0.95rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #515151;
}
.projectall_type_color {
    color: #313131 !important;
}
.news_card {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 2rem;
}
.news_card_container {
    padding-top: 5rem;
    padding-right: 0.1rem;
    max-width: 312px;
    width: 100%;
}

@media screen and (max-width:450px) {
    .projectgallery_container{
        padding-left: 18px;
        padding-right: 18px;
    }
    
    .tabpanel_container {
        align-items: flex-start;
    }
    .news_container {
        width: 100%;
    }
    .news_card {
        flex-flow: column;
        gap: 1rem;
    }
    .news_card_container {
        padding-top:3rem;
    }
}