.project_slider {

}
.project_slider .slick-dots{
    bottom: -50px !important;
    margin-left: -200px !important;
}

.project_slider .slick-dots li button{
    width: 30px !important;
    height: 30px !important;
}

.project_slider .slick-dots li.slick-active button:before {
 color: #2182FF;
}

.project_slider > .slick-prev {
    left : -10%;
}
.project_slider > .slick-next {
    right : -50px;
}

.project_slider .slick-prev, .project_slider .slick-next{
    top:60%;
}

@media screen and (max-width: 450px) {
    .project_slider .slick-prev, .project_slider .slick-next{
        top:20%;
    }
    .project_slider .slick-dots{
        /* bottom: -50px !important; */
        top: 230px !important;
        margin-left:unset !important;
    }
    .project_slider .slick-dots li {
        margin: 3px;
    }
    .project_slider .slick-list {
        /* z-index:1; */
    }
    
}