.sustainability_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.sustainability_subcontainer {
    max-width: 1080px !important;
    width: 100%;
}
.visionsus_container {
    z-index:1;
    margin-bottom: -3.5rem;
    display: flex;
    align-items: center;
    column-gap: 4rem;
}

.visionsus_bg {
    background: url("https://simonindia.com/assets/images/sus_vision_img.jpg") no-repeat;
    background-size: cover;
    max-height:400px;
    height:100%;
}
.vision_expert_subcontainer {
    display: flex;
    gap: 2rem; 
    align-items: center;
}

.sustainability_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 39.69px !important;
}

.sustainability_sub_title {
    font-family: Kumbh Sans !important;
    font-size: 1.75rem !important;
    font-weight: 700 !important;
    line-height: 42px !important;
    color: #000000;
}

.sustainability_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 35px !important;
    color: #313131;
}

.focus_content {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 30px !important;
}

.colorWhite {
    color: #FFF !important;
}
.colorBlue {
    color: #00258A !important;
}
.colorGreen {
    color: #B0D22D !important;
}
.focus_container {
    max-width: 1300px;
    width: 100%;
    display: flex;
    gap: 17rem;
    align-items: center;
}
.focus_card {
    display: flex; 
    align-items: flex-start;
    column-gap: 1rem;
}

.apply_filter {
    filter: grayscale(100);
}
.disable_filter {
    filter: unset;
}
.expertise_area{
    background-color: #ECF2F6;
    margin-top: -248px;
    padding-top: 170px;
    padding-bottom: 100px;
}
.commitment_subarea {
    display: flex;
    column-gap: 3rem;

}
.delivery_container {

}

.commitment_container {
    display: flex;
    justify-content: space-between;
}

.expertise_card {
    display: flex;
    width: 100%;   
    align-items: center;
}

.expertise_card {
    display: flex;
    width: 100%;
    
    align-items: center;
}

.expertise_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    color: #313131 !important;
}
.expertise_card_title {
    font-family: Kumbh Sans !important;
    font-size: 1.75rem !important;
    font-weight: 700 !important;
    line-height: 42px !important;
    color: #00258A !important;
}

.expert_title {
    font-family: Kumbh Sans !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 27px !important;
}



@media screen and (max-width: 450px) {
    .sustainability_container {
        padding-left: 18px;
        padding-right: 18px;
    }
    .visionsus_container {
        align-items: start;
        column-gap: 1rem;
    }
    .visionsus_container img {
        width: 30%;
    }
    .visionsus_bg {
        background: url("https://simonindia.com/assets/images/sus_vision_img_mobile.jpg") no-repeat;
        background-size: cover;
    }
    .vision_expert_subcontainer {
        flex-flow: column-reverse;
        padding-left: 18px;
        padding-right: 18px;
    }
    .expertise_card {
        flex-flow: column;
    }
    .commitment_subarea {
        flex-flow: column;
        margin-top: 2rem;
    }
    .commitment_container {
        display: block;
    }
    .focus_container {
        flex-flow: column;
        gap:3rem;
    }
}