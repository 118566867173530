.responsibility_container {
    max-width: 100%;
    width: 100%;
    background-color: #ECF2F7;
    display:flex;
    justify-content:center;
    /* padding-left: 0.75rem; */
    
}
.responsibility_background {
    justify-content:center;
    display:flex;
    width: 100%;
    background: url("https://simonindia.com/assets/images/responsibility.jpg") no-repeat;
    background-size: contain;
    background-position: right center;
    padding-top:50px;
    padding-bottom: 50px;
}

.responsibility_subcontainer {
    max-width: 1080px !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap:30px;
}
.responsibility_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 48px !important;
    color: #313131;
    margin-bottom: 30px !important;
}
.responsibility_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #5A5A5A;
    
}
.mobile_img {
    display: none;
}

@media screen and (max-width: 450px) {
    .responsibility_background {
        background: unset;
        padding-top: 0px;
        padding-bottom:20px;
    }
    .responsibility_subcontainer {
        flex-flow: column;
    }
    .mobile_img {
        width: 100%;
        display: block;
    }
    .mobile_img img {
        width: 100%;
    }
    .responsibility_content_container {
        padding: 36px;
    }
}