
.advantagecontainer{
    width: 100%;
    background-color: #DAF0FF;
    display: flex;
    justify-content: center;
}
.advantage_subcontainer {
    max-width: 1080px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 80px;
    column-gap: 30px;
}
.advantage_container {
    display: flex;
    width: 80%;
    align-items: center;
}
.advantage_content_container {
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.advantage_line {
    height: 1px;
    width:100%;
    background-color:#5A5A5A;
}
.advantage_circle_parent {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(33, 130, 255, 0.5);
}
.advantage_circle {
    width: 16px;
    height: 16px;
    border-radius: 50px;
    background-color:#2182FF;
    position:absolute;
    /* border: 10px solid rgba(33, 130, 255, 0.5); */
}
.advantage_text {
    font-family: Poppins !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 25px !important;
}

@media screen and (max-width:450px) {
    .advantage_content_container {
        flex-flow: column-reverse;
        align-items: flex-start;
        row-gap: 10px;
    }
    .advantage_content_container p {
        font-size: 1.1rem;
    }
    .advantage_content_container img {
        width: 108px;
    }
    .advantage_subcontainer {
        padding:36px;
    }
    .advantage_container {
        width: 50%;
    }
    .advantage_circle_parent {
        width: 35px;
    }
}