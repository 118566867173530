.client_slider {
    marginTop: -140px;
}

.client_slider .slick-slider {
    display: none;
}

.client_slider .slick-next, .client_slider .slick-prev {
    top: 10%;
}

.client_slider .slick-dots{
    bottom: -50px !important;
}

.client_slider .slick-dots li button{
    width: 30px !important;
    height: 30px !important;
}

.client_slider .slick-dots li.slick-active button:before {
 color: #2182FF;
}
.client_slider .slick-dots li button:before {
    color: rgba(90, 90, 90, 0.3);
    opacity: 1;
    font-size: 16px;
}

@media screen and (max-width: 450px) {
    .client_slider {
        margint-top: -20px;
    }
    .client_slider .slick-next, .client_slider .slick-prev {
        top: 30%;
    }
    .client_slider .slick-dots {
        top: 24rem;
    }
    
} 