.footerContainer {
    width: 100%;
    display: flex;
    flex-flow:column;
    align-items: center;
    background: url("https://simonindia.com/assets/images/footer_bg.jpg") no-repeat;
    background-size: cover;
    padding-top: 80px;
    padding-bottom:20px;
}

.footer_subcontainer {
    max-width: 1080px;
    width: 100%;
}
.footer_subcontainer > img {
    margin-bottom: 20px;
}
.footer_copycontainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 20px;
}
.footer_information {
    display: flex;
    margin-bottom: 80px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.footer_address {
    column-gap: 7rem;
    display: flex;
    justify-content: flex-start;
    align-items: start;
}
.links_container {
    display: flex;
    column-gap: 5rem;
    justify-content: flex-start;
    width: 100%
    
}


.footer_title {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    color: #FFF !important;
    margin-bottom: 15px !important;
    text-transform: uppercase;
    text-decoration: none !important;
}
.footer_nav {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 300 !important;
    line-height: 30px !important;
    color: #FFF !important;
    text-decoration: none !important;
}
.footer_social {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    color: #FFF;
}
.footer_link {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #FFF !important;
    text-decoration: none !important;
    cursor: pointer;
}
.footer_link:hover, .footer_title_link:hover {
    border-bottom: 1px solid #FFF;
}
.footer_lower_link {
    font-family: Kumbh Sans !important;
    font-size: 0.8rem !important;
    font-weight: 300 !important;
    line-height: 21px !important;
    color: #FFF !important;
    text-decoration: none !important;
    cursor: pointer;
}

@media screen and (max-width: 450px) {
    .footerContainer {
        background: url("https://simonindia.com/assets/images/footer_bg_mobile.png") no-repeat;
        background-size: cover;
    }
    .footer_subcontainer {
        padding-left: 36px;
        padding-right: 36px;
    }
    .footer_information {
        flex-flow: column;
        row-gap: 3rem;
        margin-bottom: 30px;
    }
    .footer_address {
        flex-flow: column;
        row-gap: 20px;
    }
    .links_container {
        flex-flow: row;
        margin-top: 30px;
        row-gap: 40px;
    }
    .footer_copycontainer {
        flex-flow: wrap;
        height: 100px;
    }
    .footer_nav {
        opacity: 0.9;
    }
    .nav_opacity {
        opacity: 0.8;
    }
}