.homenews_container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top:100px;
    padding-bottom: 140px;
}

.homenews_subcontainer {
    max-width: 1080px;
    width: 100%;
    
}
.cardContainer {
    display: block;
    gap: 30px;
    justify-content: space-between;
}

.news_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 48px !important;
    color: #313131;
    margin-bottom: 30px !important;
}
.cardContent {
    background-color: #2182FF;
    justify-content: space-between;
    min-height: 213px;
    padding: 18px;
    display: flex;
    flex-flow: column;
    border-radius: 0px 0px 5px 5px;
}
.cardContentReside {
    background-color: #ECF2F7;
} 
.newscardtitle {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 30px !important;
    color: #FFF;
}
.newscard_content {
    font-family: Kumbh Sans !important;
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    color: #FFF;
}


.newscard_tcolor {
    color: #00258A;
}
.newscard_ccolor {
    color: #5A5A5A;
}
.news_button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}
.newsButton {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    color: #2182FF !important;
    text-transform: none !important;
}


@media screen and (max-width:450px) {
    .cardContainer {
        flex-flow: column;
    }
    .news_title {
        padding-left: 20px;
    }
    .news_button {
        margin-top:40px;
        justify-content: flex-end;
    }
    .homenews_subcontainer {
        padding-left: 20px;
        padding-right: 20px;       
    }
}