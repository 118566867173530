.about_container {
    width: 100%;
    display: flex;
    justify-content: center;
}
.colorWhite {
    color: #FFF !important;
}
.about_subcontainer {
    max-width: 1080px !important;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 13rem;
}
.about_content{
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #313131;
}
.about_subtitle {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    color: #313131;
}


.strength_container {
    width: 100%;
    display: flex;
    justify-content:center;
    padding-bottom: 120px;
}
.strength_sub_container {
    max-width:1080px;
    width: 100%;
}
.strength_card_container {
    width: 100%;
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
}
.strength_card {
    background-color: #ECF2F7;
    border-radius: 5px;
    padding: 18px;
}
.strength_card_subtitle
 {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 30px !important;
    color: #00258A;
}
.strength_card_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #313131;
}

.aboutbrand_container {
    background: url('https://simonindia.com/assets/images/aboutbrandbg.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    width:100%;
    display: flex;
    justify-content: center;
    padding-top:80px;
    padding-bottom:30px;
}

.aboutbrand_subcontainer {
    width:100%;
    max-width: 1080px !important;
}
.aboutbrand_card {
    display: flex;
    gap: 50px;
    align-items: start;
    margin-top: 2rem;
}
.aboutbrand_card_button {
    width: 100%;
    display: flex;
    gap: 3rem;
    margin-top: 2rem;
}
.brandButton {
    background-color: #00258A;
    min-width: 312px;
    width:100%;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    text-align: center;
    border-radius: 5px;
}

.aboutexplore_container {
    background: url('https://simonindia.com/assets/images/explorebg.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    width:100%;
    display: flex;
    justify-content: center;
    padding-top:80px;
    padding-bottom: 80px;
}
.aboutexplore_subcontainer {
    max-width: 1080px;
    width: 100%;
}

.grow_card {
    /* display: flex; */
    width:100%;
    /* justify-content: space-between; */
    /* column-gap:20px; */
    margin-top:2rem;
}
.grow_card_title {
    //styleName: Secondary Header;
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 30px !important;
    color: #313131;
}
.grow_button {
    color: #2182FF;
}


.customer_container {
    width: 100%;
    padding-top: 120px;
    padding-bottom: 120px;
    display:flex;
    justify-content: center;
}
.customer_subcontainer {
    max-width:1080px;
    width:100%;
}
.customer_title {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    color: #313131;
    margin-top: 1.75rem !important;
}
.customer_desig{
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 24.8px !important;
    color: #00258A;
    margin-top: 1rem !important;
}
.customer_location {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 19.84px !important;
    color: #5A5A5A;
}

.storycontainer {
    width:100%;
    display: flex;
    justify-content: center;
    padding-top:80px;
    padding-bottom: 80px;
    background-color: #DAF0FF;
}

.storysubcontainer {
    max-width: 1080px;
    width: 100%;
}
.story_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    color : #313131;
    margin-bottom: 2rem !important;
}
.story_icon {
    width: 3rem !important;
    height: 3rem !important;
    color: #2182FF;
    margin-bottom: -1.5rem;
    margin-left: -.7rem;
}
.story_card {
    display: flex;
    gap: 50px;
    width: 85%;
}
.story_content {
    margin-top:2rem;
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #313131;
}
.tabs_container {
    margin-top: 1rem;
    background: url("https://simonindia.com/assets/images/story_right_bg.png") no-repeat;
    background-size: auto;
    background-position: right bottom;

}
.tabLabel {
    border-right: 1px solid #2182FF !important;
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    line-height: 20px !important;
    color: #313131 !important;
    max-width: 100%;
}
.MuiTabs-indicator {
    display: none;
}

.css-19wsa2m-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-size: 1.5rem !important;
}

.group_container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top:120px;
    padding-bottom: 150px;
}
.mission_container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top:0px;
    padding-bottom: 150px;
}
.mission_subcontainer {
    max-width: 1080px !important;
    width: 100%;
}

.group_subcontainer {
    max-width: 1080px !important;
    width: 100%;
}
.group_card_container {
    display: flex; 
    column-gap: 30px;
    margin-top: 2rem;
}
.group_card_image {
    display: flex;
    gap: 60px;
    margin-top: 3rem;
}
.mission_card_image{
    display: flex;
    margin-top: 3rem;
}

.group_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 39.69px !important;
}
.group_card {
    background-color: #ECF2F6;
    padding:18px;
    width:216px;
    min-height: 118px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.group_card_title {
    font-family: Kumbh Sans !important;
    font-size: 1.8rem !important;
    font-weight: 400 !important;
    line-height: 34.73px !important;
    color: #00258A;
}
.group_card_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #313131;
}

.rts___tabss {
    background-color: #CEE86B;
    padding:5px 0px;
}

.rts___nav___btn {

}

/* .rts___tab:hover {
    color: #2182FF;
    font-size: 32px !important;
    font-weight: 600 !important;
    line-height: 40px !important;
    background-color: unset;
} */

.rts___tab  {
    border-radius: unset !important;
    border: unset  !important;
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    color: #313131;
}

@media screen and (max-width: 450px) {
    .strength_card_container {
        display: block;
        /* flex-flow: column; */
    }
    .strength_container {
        padding-bottom: 80px;
    }
    .strength_card {
        min-height: 450px;
    }
    .strength_card_contain:not(:first-child) {
        padding-left: 5px;
    }
    .strength_card_contain:not(:last-child) {
        padding-right: 5px;
    }
    
    .strength_sub_container {
        padding-left: 18px;
        padding-right: 18px;
    }
    .about_subtitle {
        padding-left: 18px;
    }
    .aboutbrand_container {
        background: url('https://simonindia.com/assets/images/aboutbrandbg_mobile.jpg') no-repeat;
        padding-bottom:35px;
        background-size: cover;
    }    

    .aboutbrand_subcontainer {
        padding-left: 36px;
        padding-right: 36px;
    }
    .aboutbrand_card {
        flex-flow: column-reverse; 
        row-gap: 20px;
    }
    .aboutbrand_card_button {
        flex-flow: column;
        gap: 1rem;
    }
    .brandButton {
        min-width: 288px;
    }
    .aboutexplore_container {
        background: url('https://simonindia.com/assets/images/explorebg_mobile.jpg') no-repeat;
        padding-left: 36px;
        padding-right: 36px;
        background-size: cover;
    }
    .mission_container {
        padding-left: 18px;
        padding-right: 18px;
    }
    .mission_card_image img {
        width: 100%;
    }

    .customer_container {
        padding-left: 36px;
        padding-right: 36px;
    }
    .storycontainer {
        padding-left: 36px;
        padding-right: 36px;
    }
    .story_card {
        width: 100%;
        flex-flow: column;
    }
    
    .story_card img  {
        object-fit: contain;
    }

    .tabs_container {
        padding-bottom: 9rem;
        margin-left: -18px;
        margin-right: -18px;
        background-size: contain;
    }

    .group_container {
        padding-left: 36px;
        padding-right: 36px;
    }
    .group_card_container {
        /* flex-flow: column; */
        row-gap:30px;
        flex-wrap: wrap;
    }
    .group_card {
        width: 100%;
    }
    .group_card_image {
        flex-flow: column-reverse;
        margin-left: -36px;
        margin-right: -36px;
    }
    .group_card_image img {
        width: 100%;
    }
    .group_img {
        /* margin-left: -36px;
        margin-right: -36px; */
    }
}