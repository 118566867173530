.expertise_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.expertise_subcontainer {
    max-width: 1080px !important;
    width: 100%;
}

.apply_filter {
    filter: grayscale(100);
}
.disable_filter {
    filter: unset;
}
.expertise_area{
    background-color: #ECF2F6;
    margin-top: -210px;
    padding-top: 40px;
    padding-bottom: 100px;
}
.expertise_subarea {

}
.delivery_container {

}

.expertise_card {
    display: flex;
    width: 100%;
    
    align-items: center;
}

.expertise_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 40px !important;
    color: #313131 !important;
}
.expertise_card_title {
    font-family: Kumbh Sans !important;
    font-size: 1.70rem !important;
    font-weight: 700 !important;
    line-height: 42px !important;
    color: #00258A !important;
}

.expertise_subtitle {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 30px !important;
    color: #313131 !important;
}
.expertise_content {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #313131 !important;

}

.customer_title {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    color: #313131;
    margin-top: 1.75rem !important;
}
.customer_desig{
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 24.8px !important;
    color: #00258A;
    margin-top: 1rem !important;
}
.customer_location {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 19.84px !important;
    color: #5A5A5A;
    margin-top: 0.5rem !important
}

.expert_card {
    max-width: 155px;
    width: 155px;
    cursor: pointer;
}
/* .expert_card:hover .expert_card_content {
    background-color: #CEE86B;
}
.expert_card:hover .expert_card_line {
    background-color: #00258A;
}*/
.expert_card:hover .expert_title {
    color: #00258A;
} 


.expert_card_content {
    padding: 33px 18px;
    min-height: 70px;
    background-color: #FFF;
}
.expert_card_line {
    height: 2px;
    margin-bottom: 0.5rem; 
    background-color: #c1c1c1;
}

.expert_title {
    font-family: Kumbh Sans !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 27px !important;
}
.delivery_container {
    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 20px;
}

@media screen and (max-width: 450px) {
    .expertise_area {
        padding-left:36px;
        padding-right:36px;
        
    }
    .expertise_subarea {
        margin-top: 150px;
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .customer_title {
        font-size: 1.25rem !important;
    }
    
    .expertise_card {
        flex-flow: column;
    }
    .expert_card {
        width: 216px;
        max-width: 216px;
     }
    
}