
.home_project_container {
    padding-top: 20px;
    padding-bottom: 112px;
    display: flex;
    justify-content: center;
}
.home_project_subcontainer {
    max-width: 1080px;
    width: 100%;
    display:flex;
    flex-flow: column;
    row-gap:30px;
}

.project_heading {
    font-family: Kumbh Sans !important;
    font-size: 1.75rem !important;
    color: #313131 !important;
    font-weight: 700 !important;
    line-height: 35px !important
}
.project_content {
    margin-top:40px;
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    color: #5A5A5A !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    /* max-width: 670px; */
}
.project_title {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    line-height: 35px !important;
    color: #00258A;
}
.project_container {
    margin-top:20px;
    display: flex;
    column-gap:80px;
    align-items: flex-start;
}
.project_details {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    letter-spacing: 0.1em;
    color: #5A5A5A;

}
.project_details_content {
    font-family: Kumbh Sans !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 30px !important;
    color: #313131;
}

.project_details_container {
    display: flex;
    flex-flow: column;
    row-gap: 10px; 
    margin-top: 40px
}

@media screen and (max-width: 450px) {
    .home_project_container {
        padding-left: 36px;
        padding-right: 36px;
    }
    .project_heading {
        font-size: 2rem !important;
    }
    .project_container {
        flex-flow: column;
        align-items: flex-start;
        row-gap: 50px;
        column-gap:0px;   
    }
    .project_container img{
        width: 100%;
    }
}