
.partner_slider .slick-prev, .partner_slider .slick-next {
    top: 80px;
}

@media screen and (max-width:450px) {
    .partner_slider .slick-dots {
        top: 12.5rem;
    }
.partner_slider .slick-list{
    /* z-index:1; */
}

}