
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: "Kumbh Sans", sans-serif;
  src:  local("Kumbh Sans") url("./fonts/KumbhSans-Regular.ttf") format("truetype"), 
  url('https://fonts.googleapis.com/css2?family=Kumbh+Sans') format("truetype");
  font-optical-sizing: auto;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url('https://fonts.googleapis.com/css2?family=Kumbh+Sans') format("truetype");
  font-optical-sizing: auto;
  font-style: normal;
}



body, h1, h2, h3, h5, h6, p {
  font-family: Kumbh Sans, sans-serif !important;
}

.link-color {
  color: #4F9A41;
}

.link-color:hover, .link-color:active {
  color: #00258A;
}

.icon{
  align-items: center;
  display:inline-flex;
  justify-content: center;
  position:relative;
  width:24px;
  margin: 6px 10px 0 0;
  height: 2px;
  border-top:2px solid;
  transition: transform .3s;
}


.menu-icon {
  content:'';
  display:block;
  top:10px;
  right: 0;
  width: 100%;
  height: 3px;
  border-top:2px solid;
  position: absolute;
  transition: transform .3s;
}
.menu-bar {
  top:4px;
  display: block;
}
.menu-bar-opened {
  display:none;
}
.iconOpened {
  transform:rotate(45deg);
  margin-top:8px;
  width:18px;
}
.menu-iconOpened {
  transform:rotate(-90deg);
  margin-top:-12px;
  width:18px;
}

textarea {
  resize: none;
}


.service_list {
  padding-inline-start: 20px;
  list-style-image: url('https://simonindia.com/assets/images/list_checkbox.png');
}
.service_list li {
  padding-left: 7px;
}
.service_nolist {
  list-style-image: initial;
}

.slick-dots{
  bottom: -50px !important;
}


.slick-dots li.slick-active button:before {
  color: #2182FF !important;
 }
 
 .slick-dots li button:before {
     color: rgba(90, 90, 90, 0.7) !important;
     font-size: 13px;
 }