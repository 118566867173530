.leadership_container {
    width: 100%;
    display:flex;
    justify-content: center;
}
.leadership_subcontainer {
    max-width: 1080px !important;
    width: 100%;
}

.leader_title {
    font-family: Kumbh Sans !important;
    font-size: 2rem !important;
    font-weight: 700 !important;
    line-height: 39.69px !important;
    color: #313131;
}
.leader_card_container {
    margin-top: 2.5rem;
    display: flex;
    gap: 64px;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.corporate_card {
    border-bottom: 1px solid #c1c1c1;
    box-shadow: none !important;
}
.leader_card {
    max-width: 216px;
    width:216px;
    /* border-bottom: 1px solid #c1c1c1; */
    box-shadow: none !important;
    border-radius: 5px 5px 0px 0px !important;
}
.leader_media {
    /* filter: grayscale(100); */
}
.leader_media_active {
    filter:none;
}
.leader_card_active {
    filter: none;
}
.leader_card_title {
    font-family: Kumbh Sans !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    color: #313131;
    opacity: 1;
}
.leader_card_desig {
    font-family: Kumbh Sans !important;
    font-size: 16px !important;
    font-weight: 540 !important;
    line-height: 24px !important;
    color: #313131;
    opacity: 1;
}
.leader_card_desig_color {
    color: #00258A !important;
}
.fade_title {
    opacity: 0.5;
}
.leader_bottom {
    margin-bottom: 3rem !important;
}
.board_container {
    display: flex; 
    gap: 3rem; 
    margin-top: 4rem;
}
.board_team_title {
    font-family: Kumbh Sans !important;
    font-size: 1.50rem !important;
    font-weight: 700 !important;
    line-height: 48px !important;
    color: #00258A;
}
.board_team_design {
    font-family: Kumbh Sans !important;
    font-size: 1.25rem !important;
    font-weight: 500 !important;
    line-height: 36px !important;
    color: #5A5A5A;
}
.board_team_description {
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: #5A5A5A;
    
}
.board_team_description_height {
    max-height: unset !important;
    overflow-y: unset !important;
}
.board_team_description_unheight { 
    max-height: 660px; 
    overflow-y: hidden;
}

.board_button {
    margin-top:2rem !important;
    font-family: Kumbh Sans !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    padding:0;
    text-transform: none !important;
    color: #2182FF;
    float:right;
}

@media screen and (max-width: 450px) {
    .leadership_container {
        padding-left: 18px;
        padding-right: 18px;
    }
    .leader_card_container {
        justify-content: center;
        gap: 20px;
    }
    .leader_card {
        max-width: 220px;
        width: 220px;
    }
    .board_container {
        flex-flow: column;
        gap: 1rem;
        padding-left: 18px;
        padding-right: 18px;
    }
    .board_team_description_unheight {

    }
}